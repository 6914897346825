import _ from 'lodash';
export const eventFields = [
    {
        value: 'Titulo del módulo',
        name: 'module',
    },
    {
        value: 'Código de sesión',
        name: 'code_session',
    },
    {
        value: 'Sesión',
        name: 'title',
    },
    // {
    //     value: 'Hora Inicio',
    //     name: 'start_time',
    // },
    // {
    //     value: 'Hora Fin',
    //     name: 'end_time',
    // },
    {
        value: 'Tipo de Programa',
        name: 'type_program',
    },
    {
        value: 'Descripción',
        name: 'description',
    },
    {
        value: 'Desarrollo de sesión',
        name: 'summary',
    },
    {
        value: 'Observaciones a la metodología',
        name: 'observations',
    },
    {
        value: 'Comentarios de la sesión',
        name: 'comments',
    },
    {
        value: 'Tipo de Evento',
        name: 'type_event',
    },
    {
        value: 'Tipo de Sesión',
        name: 'type_session',
    },
    {
        value: 'Estado de la sesión',
        name: 'status_session',
    },
    {
        value: 'Fecha Inicio',
        name: 'start',
    },
    {
        value: 'Fecha Fin',
        name: 'end',
    },
    {
        value: 'Mentor Individual',
        name: 'individual_mentor',
    },
    {
        value: 'Asistencia tomada por',
        name: 'taken_by',
    },
];

export const beneficiaryFields = [
    {
        value: 'Padrino',
        name: 'godfather',
    },
    {
        value: '¿Está Eliminado?',
        name: 'active',
    },
    {
        value: 'Fecha de Eliminación',
        name: 'modified',
    },
    {
        value: 'Identificación del beneficiario',
        name: 'identification',
    },
    {
        value: 'Nombre del beneficiario',
        name: 'first_name',
    },
    {
        value: 'Apellido del beneficiario',
        name: 'last_name',
    },
    {
        value: 'Fecha de nacimiento',
        name: 'birthday',
    },
    {
        value: 'Tipo de beneficiario',
        name: 'type_beneficiary',
    },
    {
        value: 'Autorización uso de datos personales',
        name: 'can_use_img',
    },
    {
        value: '¿Diligenció Autorización?',
        name: 'diligence_authorization',
    },
    {
        value: 'Autorización de Tratamiento de Datos Personales',
        name: 'treatment_personal',
    },
    {
        value: 'Autorización Especial',
        name: 'special_authorization',
    },
    {
        value: 'Estado Teórico',
        name: 'beneficiary_status',
    },
    {
        value: 'Razón de deserción Teórico',
        name: 'desertion_reason',
    },
    {   value: 'Estado Práctico', 
        name: 'status_practice' },
    {
        value: 'Razón de abandono Práctico',
        name: 'desertion_reason_practice',
    },
    {   value: 'Estado Nivelación', 
        name: 'status_leveling' },
    {
        value: 'Razón de deserción Nivelación',
        name: 'desertion_reason_leveling',
    },
    {
        value: 'Sexo',
        name: 'gender',
    },
    {
        value: 'Parentesco / relación',
        name: 'kinship',
    },
    {
        value: 'Profesión / Ocupación',
        name: 'profession',
    },
    {
        value: 'Celular',
        name: 'cellphone',
    },
    {
        value: 'Correo',
        name: 'email',
    },
    {
        value: 'Instagram',
        name: 'instagram',
    },
    {
        value: 'País',
        name: 'country',
    },
    {
        value: 'Departamento',
        name: 'department',
    },
    {
        value: 'Municipio',
        name: 'city',
    },
    {
        value: 'Dirección',
        name: 'address',
    },
    {
        value: 'Colegio',
        name: 'school',
    },
    {
        value: 'Grado',
        name: 'school_grade',
    },
    {
        value: 'Salón',
        name: 'school_hall',
    },
    {
        value: 'Jornada',
        name: 'workday',
    },
    {
        value: 'Nivel',
        name: 'level',
    },
    {
        value: 'No. Madres',
        name: 'n_mothers',
    },
    {
        value: 'No. Padres',
        name: 'n_fathers',
    },
    {
        value: 'No. Madrastras',
        name: 'n_stepmothers',
    },
    {
        value: 'No. Padrastros',
        name: 'n_stepfathers',
    },
    {
        value: 'No. hermanos y hermanas',
        name: 'n_brothers_sisters',
    },
    {
        value: 'No. Abuelas',
        name: 'n_grandmothers',
    },
    {
        value: 'No. Abuelos',
        name: 'n_grandparents',
    },
    {
        value: 'No. Tíos / Tías',
        name: 'n_uncles',
    },
    {
        value: 'No. Otros familiares',
        name: 'n_others',
    },
    {
        value: '¿Padres viven juntos?',
        name: 'parents_together',
    },
    {
        value: 'Riesgos',
        name: 'risks',
    },
    {
        value: '¿Tiene dispositivo?',
        name: 'has_device',
    },
    {
        value: 'Tipo de dispositivo',
        name: 'type_device',
    },
    {
        value: 'Tipo de conexión',
        name: 'type_network',
    },
    {
        value: '¿Debe Proyecto Social?',
        name: 'social_project',
    },
    {
        value: 'Caracterización FAMOF',
        name: 'famof',
    },
    {
        value: '¿Hace cuánto viven juntos?',
        name: 'how_long_together',
    },
    {
        value: 'Vinculación especial',
        name: 'special_boding',
    },
];

export const groupBeneficiariesFields = [
    {
        value: 'Código',
        name: 'group_code',
    },
    {
        value: 'Grupo de beneficiarios',
        name: 'group_name',
    },
    {
        value: 'Colegio',
        name: 'group_school',
    },
    {
        value: 'Estado',
        name: 'group_status',
    },
    

];

export const trainingGroupFields = [
    {
        value: 'Grupo de formación',
        name: 'training_group',
    },
    {
        value: 'Mentor',
        name: 'mentor',
    },
    {
        value: 'Monitor',
        name: 'monitor',
    },
];

export const assistanceFields = [
    {
        value: 'Asistencia',
        name: 'assistance',
    },
    {
        value: 'Padrino',
        name: 'godfather',
    },
    {
        value: 'id',
        name: 'id',
    },
];

export const getFriendlyName = (field) => {
    const allFields = [
        ...trainingGroupFields,
        ...beneficiaryFields,
        ...groupBeneficiariesFields,
        ...eventFields,
        ...assistanceFields,
    ];

    const fieldName = _.find(allFields, { name: field });
    if (fieldName) return fieldName.value;
    return 'Sin nombre de columna';
};
